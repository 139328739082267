














































































































































import Vue from 'vue'
import HelpButton from '@/components/help/HelpButton.vue'
import InfoAlert from '@/components/help/InfoAlert.vue'
import PanelCompareHelp from '@/components/help/PanelCompareHelp.vue'
import { ActiveState, GeneState, TableHeader } from '@/types/ui-types'
import download from '@/utils/download'
import Papa from 'papaparse'
import { Gene, ParsedGene } from '@/types/panel-types'
// import { transpose } from '@/utils/arrays'
import ResizablePage from '@/components/ResizablePage.vue'

export default Vue.extend({
  components: {
    HelpButton,
    InfoAlert,
    PanelCompareHelp,
    ResizablePage,
  },
  name: 'PanelCompare',
  props: {
    help: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    visibleInstitutions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      validSeparators: /[ ,;\s]+/,
      search: '',
    }
  },
  computed: {
    filteredHeaders(): Array<TableHeader> {
      const fHeaders = new Array<TableHeader>()
      for (let i = 0; i < this.headers.length; i++) {
        const header = this.headers[i] as TableHeader
        if (header.visible) {
          fHeaders.push(header)
        }
      }
      return fHeaders
    },
  },
  watch: {},
  methods: {
    handleHelp() {
      this.$emit('help')
    },
    showChip(match: ParsedGene) {
      return match && match.state !== GeneState.NOT_FOUND
    },
    getMatches(item: any, header: any): ParsedGene[] {
      const label: string = header.value
      return item[label] as ParsedGene[]
    },
    customSort(items: any[], sortBy: string[], sortDesc: boolean[]): any[] {
      items.sort((a: any, b: any) => {
        if (!sortBy[0]) {
          return 0
        }
        const aItem = a[sortBy[0]][0].gene.name
        const bItem = b[sortBy[0]][0].gene.name
        const desc = sortDesc[0]
        if (aItem > bItem) {
          return desc ? 1 : -1
        }
        if (aItem < bItem) {
          return desc ? -1 : 1
        }
        return 0
      })
      return items
    },
    customFilter(value: any, search: string | null): boolean {
      if (value && search) {
        const searchItems = search.split(this.validSeparators)
        const cellValue = value.gene.name.toUpperCase()
        for (let i = 0; i < searchItems.length; i++) {
          const searchItem = searchItems[i].toUpperCase()
          if (cellValue.indexOf(searchItem) > -1) {
            return true
          }
        }
        return false
      }
      return true
    },
    toggleInstitution(institution: ActiveState) {
      this.$emit('toggleInstitution', institution)
    },
    downloadComparePanels() {
      //use filteredHeaders to remove geneId and hidden columns
      const csvHeaders = this.filteredHeaders.map((h) => h.text).slice()
      csvHeaders.splice(1, 0, 'HGNC')
      const filteredHeaderValues = this.filteredHeaders
        .map((h) => h.value)
        .slice()
      filteredHeaderValues.splice(1, 0, 'HGNC')
      const csvItems = []
      for (let j = 0; j < this.items.length; j++) {
        const row = []
        for (let i = 0; i < filteredHeaderValues.length; i++) {
          const h = filteredHeaderValues[i]
          let result = null
          if (h === 'HGNC') {
            //use Gene Search to output HGNC symbol value in 2nd column
            const firstCol = this.filteredHeaders[0].value
            const currentParsedGene = (this.items as any)[j][firstCol][0]
            result = [new ParsedGene(currentParsedGene.gene)]
            result[0].state = GeneState.SYMBOL
            if (
              currentParsedGene.state === GeneState.SYNONYM ||
              currentParsedGene.state === GeneState.FUSION ||
              currentParsedGene.state === GeneState.INTRON
            ) {
              result[0].gene = new Gene(currentParsedGene.realGene.symbol)
            }
          } else {
            result = (this.items as any)[j][h]
          }
          //skip invalid results
          if (result && result.length > 0) {
            const genes: string[] = []
            result.forEach((r: ParsedGene) => {
              if (r && r.state === GeneState.NOT_FOUND) {
                genes.push('')
              } else {
                const geneName = r.gene.name
                genes.push(geneName)
              }
            })
            row.push(genes.join(' '))
          }
        }
        csvItems.push(row)
      }
      const csv = Papa.unparse({
        fields: csvHeaders,
        data: csvItems,
      })
      download('compare_panels.csv', csv, 'text/csv')
    },
    resize() {
      const elt: any = this.$refs.resizablePage
      if (elt !== undefined) {
        elt.onResize()
      }
    },
  },
  mounted() {},
})
