












































import Vue from 'vue'
import HelpButton from '@/components/help/HelpButton.vue'

export default Vue.extend({
  name: 'DialogTemplate',
  components: {
    HelpButton,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: '',
    },
    help: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({}),
  computed: {},
  watch: {},
  mounted() {},
  destroyed() {},
  methods: {
    close() {
      this.$emit('closing')
    },
    handleHelp() {
      this.$emit('help')
    }
  },
})
