


































































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import { ParsedGenes } from '@/types/panel-types'
import GeneParsedContent from '@/components/GeneParsedContent.vue'
import ParsedSearchHelp from '@/components/help/ParsedSearchHelp.vue'
import HelpButton from '@/components/help/HelpButton.vue'
import InfoAlert from '@/components/help/InfoAlert.vue'
import download from '@/utils/download'
import Papa from 'papaparse'
import { transpose } from '@/utils/arrays'
import { GeneState } from '@/types/ui-types'
import GeneEntryTitle from '@/components/GeneEntryTitle.vue'

export default Vue.extend({
  components: {
    GeneParsedContent,
    ParsedSearchHelp,
    HelpButton,
    InfoAlert,
    GeneEntryTitle,
  },
  name: 'ParsedInput',
  props: {
    help: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    formattedGenes: {
      type: ParsedGenes,
    },
    simpleSearch: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    geneState: GeneState,
  }),
  computed: {
    ...mapGetters({
      // userGenes: 'getUserGenesSorted',
      // parsedGenes: 'getParsedGenes',
      chipOutlined: 'getChipOutlined',
    }),
    showInvalid(): boolean {
      return this.formattedGenes.invalidGenes.length > 0
    },
    showSynonym(): boolean {
      return this.formattedGenes.synonymFoundGenes.length > 0
    },
    showSymbol(): boolean {
      return this.formattedGenes.symbolFoundGenes.length > 0
    },
    showFusion(): boolean {
      return this.formattedGenes.fusionFoundGenes.length > 0
    },
    showIntron(): boolean {
      return this.formattedGenes.intronFoundGenes.length > 0
    },
    showAny(): boolean {
      return (
        this.showInvalid ||
        this.showSynonym ||
        this.showSymbol ||
        this.showFusion ||
        this.showIntron
      )
    },
    noData(): boolean {
      return (
        !this.showInvalid &&
        !this.showSynonym &&
        !this.showSymbol &&
        !this.showFusion &&
        !this.showIntron
      )
    },
  },
  watch: {
    // parsedGenes: 'formatGenes',
  },
  destroyed() {},
  methods: {
    handleHelp() {
      this.$emit('help')
    },
    downloadParsedSearch() {
      const headers = [
        this.$t('parsedInput.invalid.text').toString(),
        this.$t('parsedInput.synonyms.text').toString(),
        this.$t('parsedInput.symbols.text').toString(),
        this.$t('parsedInput.fusions.text').toString(),
        this.$t('parsedInput.introns.text').toString(),
      ]
      const columns = [
        this.formattedGenes.invalidGenes.map((pg) => pg.gene.name),
        this.formattedGenes.synonymFoundGenes.map((pg) => pg.gene.name),
        this.formattedGenes.symbolFoundGenes.map((pg) => pg.gene.name),
        this.formattedGenes.fusionFoundGenes.map((pg) => pg.gene.name),
        this.formattedGenes.intronFoundGenes.map((pg) => pg.gene.name),
      ]
      const transposed = transpose(columns)

      // const csv = new CSVContent(headers, columns)
      const csv = Papa.unparse({
        fields: headers,
        data: transposed,
      })
      download('parsed_search.csv', csv, 'text/csv')
    },
  },
})
