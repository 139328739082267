

















import Vue from 'vue'

export default Vue.extend({
  name: 'HelpButton',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    isActive() {
      return this.active ? 'primary--text' : ''
    },
  },
  watch: {},
  mounted() {},
  destroyed() {},
  methods: {
    handleClick() {
      this.$emit('action')
    },
  },
})
