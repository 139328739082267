















































import Vue from 'vue'

export default Vue.extend({
  components: {},
  name: 'RecallSearches',
  props: {
    lastSearches: {
      type: [],
      default: () => [],
    },
  },
  data: () => ({}),
  computed: {
    empty() {
      return this.lastSearches.length == 0
    },
  },
  watch: {},
  methods: {
    fillLastSearch(search: string) {
      this.$emit('recallLastSearch', search)
    },
    lastSearchLabel(search: string) {
      if (search.length > 5) {
        return search.substring(0, 5) + '...'
      }
      return search
    },
    resetLastSearches() {
      this.$emit('resetLastSearches')
    },
    truncatedSearch(search: string) {
      if (search.length > 40) {
        return search.substring(0, 40) + '...'
      }
      return search
    },
  },
  mounted() {},
})
