




























import Vue from 'vue'

export default Vue.extend({
  name: 'ParsedSearchHelp',
  props: {
    simpleSearch: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {},
  watch: {},
  mounted() {},
  destroyed() {},
  methods: {},
})
