



















































import Vue from 'vue'
import { GeneState } from '@/types/ui-types'
import { FullGene, Gene, ParsedGene } from '@/types/panel-types'

export default Vue.extend({
  name: 'PanelResultsDialogHelp',
  props: {},
  data: () => ({}),
  computed: {
    items() {
      const it = []
      it.push({
        geneSearch: new ParsedGene(new Gene('TP53'), GeneState.SYMBOL),
        explain1: this.$t('help.panelCompare.part3.exactMatch'),
        explain2: this.$t('help.panelCompare.part3.symbolMatching'),
        explain3: this.$t('help.panelCompare.part3.symbolInput'),
      })
      it.push({
        geneSearch: new ParsedGene(
          new Gene('BRAF1'),
          GeneState.SYNONYM,
          new FullGene('BRAF', '', [], '', '')
        ),
        explain1: this.$t('help.panelCompare.part3.exactMatch'),
        explain2: this.$t('help.panelCompare.part3.symbolMatching'),
        explain3: this.$t('help.panelCompare.part3.synonymInput'),
      })
      it.push({
        geneSearch: new ParsedGene(new Gene('ALK_FUSION'), GeneState.FUSION),
        explain1: this.$t('help.panelResult.part3.anyMatch'),
        explain2: this.$t('help.panelResult.part3.fusionMatching'),
        explain3: this.$t('help.panelCompare.part3.symbolInput'),
      })
      it.push({
        geneSearch: new ParsedGene(new Gene('BCL6_INTRON1'), GeneState.INTRON),
        explain1: this.$t('help.panelResult.part3.anyMatch'),
        explain2: this.$t('help.panelResult.part3.intronMatching'),
        explain3: this.$t('help.panelCompare.part3.symbolInput'),
      })
      it.push({
        geneSearch: new ParsedGene(new Gene('ABL1'), GeneState.NOT_FOUND),
        explain1: '',
        explain2: this.$t('help.panelCompare.part3.noMatch'),
        explain3: this.$t('help.panelCompare.part3.symbolInput'),
      })
      return it
    },
    parsedSearch() {
      return this.$t('parsedInput.title.text')
    },
  },
  watch: {},
  mounted() {},
  destroyed() {},
  methods: {
    showChip(item: ParsedGene) {
      return item.state !== GeneState.NOT_FOUND
    },
  },
})
