











































































import Vue from 'vue'

export default Vue.extend({
  name: 'ParsedListItem',
  props: {
    color: String,
    items: Array,
    title: String,
    synonym: Boolean,
    hidable: Boolean,
    showLargeSets: Boolean,
    square: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    visible: true,
    textOnly: false,
  }),
  computed: {
    tooLarge(): boolean {
      return this.items.length >= 500
    },
  },
  watch: {},
  mounted() {},
  destroyed() {},
  methods: {
    showChips() {
      return (
        (this.showLargeSets || !this.tooLarge || this.textOnly) && this.visible
      )
    },
  },
})
