



























import { FullGene, Gene, ParsedGene } from '@/types/panel-types'
import { GeneState } from '@/types/ui-types'
import {
  formatStateColor,
  formatStateIcon,
  formatStateShape,
} from '@/utils/formatting'
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'GeneEntry',
  props: {
    parsedGene: {
      type: Object,
      default: () => new ParsedGene(new Gene(''), undefined, undefined),
    },
    icon: Boolean,
  },
  data: () => ({}),
  computed: {
    ...mapGetters({
      chipOutlined: 'getChipOutlined',
    }),
    synonym() {
      return (this.parsedGene as ParsedGene).state === GeneState.SYNONYM
    },
    formatShape() {
      return formatStateShape((this.parsedGene as ParsedGene).state)
    },
    geneName() {
      const gene = (this.parsedGene as ParsedGene).gene
        ? (this.parsedGene as ParsedGene).gene.name
        : (this.parsedGene as ParsedGene as any).name
      return gene
    },
    realGeneName() {
      const gene = (this.parsedGene as ParsedGene).realGene
        ? ((this.parsedGene as ParsedGene).realGene as FullGene).symbol
        : '?'
      return gene
    },
    formatColor() {
      return formatStateColor((this.parsedGene as ParsedGene).state)
    },
    formatIcon() {
      return formatStateIcon((this.parsedGene as ParsedGene).state)
    },
    isFusion() {
      return (this.parsedGene as ParsedGene).state === GeneState.FUSION
    },
  },
  watch: {},
  mounted() {},
  destroyed() {},
  methods: {},
})
