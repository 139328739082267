


























































import Vue from 'vue'
import { GeneState } from '@/types/ui-types'
import { FullGene, Gene, ParsedGene } from '@/types/panel-types'

export default Vue.extend({
  name: 'PanelCompareHelp',
  props: {},
  data: () => ({}),
  computed: {
    items() {
      const it = []
      it.push({
        geneSearch: new ParsedGene(new Gene('TP53'), GeneState.SYMBOL),
        panelGene: new ParsedGene(new Gene('TP53'), GeneState.SYMBOL),
        explain1: this.$t('help.panelCompare.part3.exactMatch'),
        explain2: this.$t('help.panelCompare.part3.symbolMatching'),
        explain3: this.$t('help.panelCompare.part3.symbolInput'),
      })
      it.push({
        geneSearch: new ParsedGene(new Gene('TENT5C'), GeneState.SYMBOL),
        panelGene: new ParsedGene(
          new Gene('FAM46C'),
          GeneState.SYMBOL_TO_SYNONYM
        ),
        explain1: this.$t('help.panelCompare.part3.anyMatch'),
        explain2: this.$t('help.panelCompare.part3.synonymMatching'),
        explain3: this.$t('help.panelCompare.part3.symbolInput'),
      })
      it.push({
        geneSearch: new ParsedGene(
          new Gene('BRAF1'),
          GeneState.SYNONYM,
          new FullGene('BRAF', '', [], '', '')
        ),
        panelGene: new ParsedGene(
          new Gene('BRAF'),
          GeneState.SYNONYM_TO_SYMBOL
        ),
        explain1: this.$t('help.panelCompare.part3.exactMatch'),
        explain2: this.$t('help.panelCompare.part3.symbolMatching'),
        explain3: this.$t('help.panelCompare.part3.synonymInput'),
      })
      it.push({
        geneSearch: new ParsedGene(
          new Gene('KRAS1'),
          GeneState.SYNONYM,
          new FullGene('KRAS', '', [], '', '')
        ),
        panelGene: new ParsedGene(
          new Gene('KRAS2'),
          GeneState.SYNONYM,
          new FullGene('KRAS', '', [], '', '')
        ),
        explain1: this.$t('help.panelCompare.part3.anyMatch'),
        explain2: this.$t('help.panelCompare.part3.synonymMatching'),
        explain3: this.$t('help.panelCompare.part3.synonymInput'),
      })
      it.push({
        gene: new Gene('BRCA1'),
        panel: '',
        geneSearch: new ParsedGene(new Gene('BRCA1'), GeneState.SYMBOL),
        panelGene: new ParsedGene(new Gene('BRCA1'), GeneState.NOT_FOUND),
        explain1: this.$t('help.panelCompare.part3.noMatch'),
        explain2: '',
        explain3: this.$t('help.panelCompare.part3.symbolInput'),
      })
      return it
    },
    parsedSearch() {
      return this.$t('parsedInput.title.text')
    },
  },
  watch: {},
  mounted() {},
  destroyed() {},
  methods: {
    showChip(item: ParsedGene) {
      return item.state !== GeneState.NOT_FOUND
    },
  },
})
