var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('panelCompare.title.text'))+" "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.items || _vm.items.length == 0,"icon":""},on:{"click":_vm.downloadComparePanels}},on),[_c('v-icon',[_vm._v("mdi-download ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('button.download.tooltip')))])]),_c('help-button',{attrs:{"active":_vm.help},on:{"action":function($event){return _vm.handleHelp()}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('button.showHide.tooltip'))+" "+_vm._s(_vm.$t('button.help.text'))+" ")])]},proxy:true}])})],1),_c('v-card-text',[_c('info-alert',{attrs:{"active":_vm.help},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('panel-compare-help')]},proxy:true}])}),_c('v-card',{staticClass:"darker-border",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('panelCompare.table.tools.text'))+": ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"body-1"},[_vm._v(" "+_vm._s(_vm.$t('panelCompare.table.institutions.text'))+": ")]),_vm._l((_vm.visibleInstitutions),function(institution,index){return _c('v-chip',{key:index,staticClass:"ma-1",attrs:{"filter":"","active-class":"primary lighten-1","input-value":institution.active},on:{"click":function($event){return _vm.toggleInstitution(institution)}}},[_vm._v(" "+_vm._s(institution.id)+" ")])})],2)],1),_c('v-text-field',{attrs:{"label":_vm.$t('panelCompare.table.search.text'),"clearable":"","prepend-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('resizable-page',{scopedSlots:_vm._u([{key:"table",fn:function(tableProps){return [_c('v-data-table',{staticClass:"fixed-column",attrs:{"height":tableProps.tableHeight,"headers":_vm.filteredHeaders,"items":_vm.items,"item-key":"geneId","sort-by":"gene","custom-sort":_vm.customSort,"custom-filter":_vm.customFilter,"search":_vm.search,"fixed-header":""},scopedSlots:_vm._u([(_vm.items.length > 0)?{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.geneId},_vm._l((_vm.filteredHeaders),function(header){return _c('td',{key:header.value},[(_vm.getMatches(item, header).length > 1)?[_c('v-expansion-panels',{staticClass:"dense transparent",attrs:{"flat":"","accordion":""}},[_c('v-expansion-panel',{staticClass:"transparent"},[_c('v-expansion-panel-header',{staticClass:"transparent"},[_vm._v(" "+_vm._s(_vm.$tc( 'count.gene', _vm.$n(_vm.getMatches(item, header).length) ))+" ")]),_c('v-expansion-panel-content',{staticClass:"transparent"},[_vm._l((_vm.getMatches(
                                item,
                                header
                              )),function(match,index){return [(_vm.showChip(match))?_c('gene-entry',{key:index,attrs:{"parsedGene":match,"icon":""}}):_vm._e()]})],2)],1)],1)]:[_vm._l((_vm.getMatches(item, header)),function(match,index){return [(_vm.showChip(match))?_c('gene-entry',{key:index,attrs:{"parsedGene":match,"icon":""}}):_vm._e()]})]],2)}),0)}),0)]}}:null],null,true)})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }