


























import Vue from 'vue'

export default Vue.extend({
  name: 'MainContentTemplate',
  props: {
    twoCols: {
      type: Boolean,
      default: true,
    },
    outter: {
      type: Boolean,
      default: false,
    },
    header: {
      type: Boolean,
      default: false,
    },
    even: {
      type: Boolean,
      default: false,
    },
    hideLeft: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    rightColWidth() {
      if (this.hideLeft) {
        return 12
      }
      return this.even ? 6 : 8
    },
  },
})
