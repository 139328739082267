













































import { ParsedGenes } from '@/types/panel-types'
import Vue from 'vue'
import ParsedListItem from '@/components/home/ParsedListItem.vue'
import { formatStateColor } from '@/utils/formatting'
import { GeneState } from '@/types/ui-types'

export default Vue.extend({
  name: 'GeneParseContent',
  components: { ParsedListItem },
  props: {
    showGenes: Array,
    parsedGenes: ParsedGenes,
    showLargeSets: Boolean,
  },
  data: () => ({}),
  methods: {},
  computed: {
    invalidColor() {
      return formatStateColor(GeneState.INVALID)
    },
    symbolColor() {
      return formatStateColor(GeneState.SYMBOL)
    },
    synonymColor() {
      return formatStateColor(GeneState.SYNONYM)
    },
    fusionColor() {
      return formatStateColor(GeneState.FUSION)
    },
    intronColor() {
      return formatStateColor(GeneState.INTRON)
    },
  },
  mounted() {},
})
