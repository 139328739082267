

























































































import Vue from 'vue'
import { DUCK_DUCK_GO, GOOGLE_RETENTION } from '@/utils/apis'
import { setCookie } from '@/utils/cookies'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'GdprInfo',
  props: {
    snack: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    detailsVisible: false,
    redirect: DUCK_DUCK_GO,
    retention: GOOGLE_RETENTION,
    acceptClicked: false,
    refuseClicked: false,
  }),
  computed: {},
  mounted() {},
  methods: {
    ...mapActions(['clearLastSearches']),
    accept() {
      this.acceptClicked = true
      this.$emit('response', true)
      setCookie('GDPR_ACCEPT', true, 365)
    },
    refuse() {
      this.refuseClicked = true
      this.$emit('response', false)
      setCookie('GDPR_ACCEPT', false, 0)
      setCookie('firstTime', true, 0)
      this.clearLastSearches()
      setTimeout(() => {
        window.location.assign(this.redirect)
      }, 1000)
    },
  },
})
