










































































































































































import Vue from 'vue'
import VueI18n from 'vue-i18n'

export default Vue.extend({
  name: 'NavigationMenu',
  props: {
    hueRotation: {
      type: Number,
      default: 0,
    },
    saturation: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    mini: false,
    activeClass: 'primary lighten-1 font-weight-bold',
    activeClassExact: 'primary lighten-2 font-weight-bold',
  }),
  computed: {
    iconRotation() {
      if (this.mini) {
        return 'transform: rotate(90deg)'
      }
      return 'transform: rotate(270deg)'
    },
    hueRotationFilter() {
      return 'filter: hue-rotate(' + this.hueRotation + 'deg)'
    },
    imageStyle() {
      return 'margin: auto; filter: saturate(' + this.saturation + ')'
    },
    isActiveHome() {
      const route = this.$route as any
      //skip default route which is handled by VueRouter
      if (route.name !== 'home' || route.params.tab === 'results') {
        return false
      }
      return route.name === 'home'
    },
    isActiveExplore() {
      const route = this.$route as any
      //skip default route which is handled by VueRouter
      if (route.name !== 'explore') {
        return false
      }
      if (route.params.tab === 'panels' && route.params.item === '0') {
        return false
      }
      return route.name === 'explore'
    },
    isActiveUtils() {
      const route = this.$route as any
      if (route.name !== 'utils') {
        return false
      }
      //skip default route which is handled by VueRouter
      if (route.params.tab === 'panels' && route.params.item === '0') {
        return false
      }
      return route.name === 'utils'
    },
    isActiveHelp() {
      const route = this.$route as any
      //skip default route which is handled by VueRouter
      if (route.name !== 'help' || route.params.tab === 'files') {
        return false
      }
      return route.name === 'help'
    },
    langs(): any[] {
      return (this.$i18n as VueI18n).availableLocales.map((l) => {
        let text = l.toUpperCase()
        if (l !== 'en' && l !== 'fr') {
          text += ' (beta)'
        }
        return { text: text, value: l }
      })
    },
  },
  mounted() {},
  methods: {
    handleLanguageChange(value: string) {
      this.$i18n.locale = value
      localStorage.locale = value
    },
  },
})
