













































































































































import { Institution } from '@/types/panel-types'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { ListItem } from '@/types/ui-types'

export default Vue.extend({
  components: {},
  name: 'InstitutionDetails',
  props: {
    institution: {
      type: Object,
      default: () => new ListItem(new Institution('', '', '', '', []), true),
    },
    editable: {
      type: Boolean,
      default: false,
    },
    panels: {
      type: Array,
      default: () => [],
    },
    showReadOnlyPanels: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      institutionValid: true,
      nameRules: [
        (v: string) => !!v || this.$t('institutionDetails.name.rules.required'),
        (v: string) =>
          (v && v.length <= 50) ||
          this.$t('institutionDetails.name.rules.length'),
        (v: string) =>
          !v ||
          /^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/.test(v) ||
          this.$t('institutionDetails.name.rules.valid'),
      ],
      emailRules: [
        (v: string) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          this.$t('institutionDetails.email.rules.valid'),
      ],
      phoneRules: [
        (v: string) =>
          !v ||
          /^(1\s|1|)?((\(\d{3}\))|\d{3})(-|\s)?(\d{3})(-|\s)?(\d{4})$/.test(
            v
          ) ||
          this.$t('institutionDetails.phone.rules.valid'),
      ],
    }
  },
  watch: {
    institutionValid: 'emitInstitutionValid',
  },
  computed: {
    ...mapGetters({
      chipOutlined: 'getChipOutlined',
    }),
    validateWebSite(): any {
      var websitePattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i'
      ) // fragment locator
      const websiteValidate = (v: string) =>
        !v ||
        websitePattern.test(v) ||
        this.$t('institutionDetails.website.rules.valid')
      return [websiteValidate]
    },
  },
  methods: {
    linkTo(link: string, linkType: string) {
      const linkPrefix = linkType == 'phone' ? 'tel:' : 'mailto:'
      return linkPrefix + link
    },
    handleNameChange(event: string[]) {
      this.$emit('name-changed', event)
    },
    emitInstitutionValid() {
      if (this.institution) {
        this.institution.valid = this.institutionValid
        this.$emit('institution-valid', this.institution)
      }
    },
    formatNumber() {
      var x = this.institution.item.phone
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.institution.item.phone = !x[2]
        ? x[1]
        : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
    },
  },
  mounted() {},
})
